import { useAppSelector, RootState } from "@slices/store";
import React from "react";

function UserImage(props: {
  isRound?: boolean;
  email: string;
  src?: string;
  size: number;
  name: string;
  variant?: "rounded" | "circular" | "square" | undefined;
}) {
  const [url, setUrl] = React.useState<string>(
    props.src ?? "https://ui-avatars.com/api/?name=" + props.name + "&background=0D8ABC&color=fff&size=128"
  );

  const employee = useAppSelector((state: RootState) => state.employee);

  React.useEffect(() => {
    if (!props.src) {
      setUrl(
        employee.employeeInfo?.employeeThumbnail
          ? employee.employeeInfo?.employeeThumbnail
          : "https://ui-avatars.com/api/?name=" + props.name + "&background=0D8ABC&color=fff&size=128"
      );
    } else {
      setUrl(
        employee.employeeInfo?.employeeThumbnail
          ? employee.employeeInfo?.employeeThumbnail
          : "https://ui-avatars.com/api/?name=" + props.name + "&background=0D8ABC&color=fff&size=128"
      );
    }
  }, [employee.employeeInfo]);

  return (
    <>
      <img
        style={{
          borderRadius: props.isRound ? "50%" : "5%",
          height: props.size,
          width: props.size,
        }}
        referrerPolicy="no-referrer"
        alt={props.name}
        loading="lazy"
        src={url.replace("=s100", "=s500")}
      />
    </>
  );
}

export default UserImage;
