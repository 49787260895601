import { lazy } from "react";

const PersonalizedOrgChart = lazy(() => import("./personal-org-chart/orgTree"));
const CompanyOrgChart = lazy(() => import("./company-org-chart/orgChart"));
// const DepartmentOrgChart = lazy(() => import("./department-org-chart/subHeader"));
// const TeamOrgChart = lazy(() => import("./team-org-chart/subHeader"));

export const View = {
  PersonalizedOrgChart,
  CompanyOrgChart,
};
